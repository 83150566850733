.industrial-contact-page {
  .card {
    display: grid;
    grid-template-rows: 1fr;
    grid-template-columns: 40px 1fr 40px;
    grid-auto-flow: column;
    grid-gap: 2rem;

    max-width: 500px;

    margin-left: auto;
    margin-right: auto;
    padding: 2rem;

    > *:first-child,
    > *:last-child {
      position: relative;
      > * {
        position: absolute;
        transform: translateY(-50%);
        top: 50%;
      }
    }

    p,
    p.title {
      margin: 0;
      line-height: 1.2;
    }
  }

  .rectangle {
    color: #444;
    background-color: white;
    border-radius: 20px;
    border-color: #444;
  }

  .content {
    max-height: calc(80vh - 200px);
    padding-bottom: 50px;
  }
}

.form-contact-footer {
  --container-width: 100%;
  background: var(--light-blue);
  text-align: center;
  margin: calc(var(--padding) * -1);
  margin-top: var(--padding);
  border-bottom-right-radius: 0.3rem;
  border-bottom-left-radius: 0.3rem;
  padding: var(--padding);
  color: white;

  button {
    display: block;
    margin: 0 auto;
    margin-bottom: 1rem;

    &:last-child {
      margin-bottom: 0;
    }
  }
}
