
.services-container {
  max-height: calc(90vh - 220px);
  // margin-top: 160px;
  overflow-x: hidden;
  display: flex;
  flex-flow: row;
  flex-wrap: nowrap;
  flex-wrap: wrap;
  justify-content: center;
  padding: 20px 0;
}

.warranty-terms-modal {
  padding-top: 2.5rem;
}

.services-page {
  .page-container {
    --container-width: 70vw;
    --container-max-width: 900px;

    width: '70vw';
    padding-top: '3rem';
  }

  .content {
    display: flex;
    flex-flow: row;
    flex-wrap: nowrap;
    flex-wrap: wrap;
    justify-content: center;
  }
}