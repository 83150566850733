.TermOfUseModal-backdrop {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;

  background-color: #00000090;

  visibility: visible;
  opacity: 1;
  z-index: 998;
  overflow-y: auto;

  padding: 1.5rem;

  &.hidden {
    visibility: hidden;
    opacity: 0;
    transition: .3s;
  }
}
.TermOfUseModal {
  --padding: 1.5rem;

  position: relative;
  margin: 0 auto;
  text-align: left;

  max-width: calc(90vw - calc(var(--padding) * 2));

  background-color: white;
  color: #444;
  padding: var(--padding);
  border-radius: .3rem;

  visibility: visible;
  opacity: 1;
  transition: .3s;
  z-index: 999;

  &.hidden {
    visibility: hidden;
    opacity: 0;
    top: 0;
    transition: .1s;
  }
  &.large {
    width: 700px;
  }
  h1 {
    margin-top: 6px;
    margin-bottom: 6px;
    

    color: var(#444);
    font-weight: 500;
    font-size: 1.5rem;
  }
  button {
    width: 100%;
    height: 50px;
    margin-bottom: 2px;
  }
  button p {
    font-size: 10px;
  }
}

@media (min-width: 700px) {
  .TermOfUseModal {
    width: 400px;
}
.TermOfUseModal-backdrop {
  padding: 2rem;
}
}