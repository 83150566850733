.service-card {
  --width: calc(90px + 5vw);
  --height: calc(140px + 8vw);

  position: relative;
  display: inline-block;
  width: var(--width);
  height: var(--height);

  background-color: #fff;
  border-radius: .3rem;
  padding: .7rem;
  margin: .35rem;
  margin-bottom: 30px;

  text-align: center;
  color: #444;

  border: 1px solid #999;

  &.checked {
    border: 3px solid var(--green, green);
    transition: .1s;

    .icon-check {
      position: absolute;
      top: -24px;
      left: 50%;
      transform: translateX(-50%);
      border-radius: 100%;
      padding: 4px;
      background-color: white;
      border: 3px solid var(--green, green);

    }
  }

  .title {
    position: absolute;
    transform: translate(-50%, -50%);
    top: calc(20px + 1.8vw);
    left: 50%;

    width: calc(100px + 3vw);
    max-width: 100%;

    font-size: calc(10px + .3vw);
    font-weight: 600;
    font-style: oblique;
    text-align: center;
    padding-top: 1rem;
    margin: 0;
  }
  .description {
    height: calc(40px + 2vw);
    margin: 0;
    font-size: calc(7px + .3vw);

    overflow: hidden;
    line-height: 1.2;
  }
  footer {
    text-align: justify;
  }
  .price-badge {
    margin-bottom: .5rem;
    margin-top: calc(60px + 2vw);
    font-size: calc(8px + .2vw);

    &.hidden {
      opacity: 0;
    }
  }

  footer {
    position: absolute;
    bottom: .3rem;
    width: calc(100% - 1.4rem);
  }

  button {
    font-size: calc(8px + .2vw);
    padding-left: 0;
    padding-right: 0;
  }
}

@media screen and (max-width: 576px) {
  .service-card {
    width: calc(90vw - 2rem);
    max-width: 220px;
    height: 50vh;
    max-height: 300px;

    .title {
      top: calc(20% + 1.8vw);
      width: calc(200px + 3vw);
      font-size: calc(14px + .3vw);
    }
    .description {
      height: calc(20% + 2vw);
      font-size: calc(9px + .3vw);
    }
    .price-badge {
      margin-top: calc(100px + 2vw);
      font-size: calc(10px + .2vw);
    }

    button {
      font-size: calc(9px + .2vw);
    }
  }
}