.finances-page {
  @media screen and (min-width: 768px) {
    padding-top: 50px;
  }


  .invoice-type-switch {
    --step-width: 140px;
  }

  .finances-card {
    max-width: 500px;

    margin-left: auto;
    margin-right: auto;

    padding: 1.2rem;

    .badge {
      position: absolute;
      top: .7rem;
      right: 1rem;
    }

    .price {
      font-size: 18px;
      font-weight: bold;

      margin-bottom: 0;
    }

    footer {
      text-align: center;
    }
  }

  .carta-quitacao-ops-modal {
    text-align: left;

    footer {
      background-color: var(--dark-blue);

      padding: 1rem;
      margin: calc(var(--padding) * -1);
      margin-top: var(--padding);

      border-bottom-left-radius: 5px;
      border-bottom-right-radius: 5px;

      button {
        margin: 0;
      }
    }
  }

  .carta-quitacao-modal {
    img {
      cursor: pointer;
      margin: 2rem auto;
    }
  }

  .content {
    max-height: calc(100vh - 220px);
    max-width: var(--max-width);
    overflow-x: hidden;
  }

  .chart {
    text-align: left;
    max-width: 100%;
    overflow: hidden;
    position: relative;

    .title {
      font-weight: bold;
      font-size: 16px;
      font-style: oblique;

      margin: 1rem 0;

      .value {
        display: block;
        font-size: 22px;
        color: #fd961d;

      }
    }
    .chart-container {
      width: 100%;
      height: 150px;
    }
  }

  .filter-modal {
    position: relative;

    .title {
      margin-bottom: 0;
      margin-top: 1rem;
      color: var(--light-blue-shade);
      font-weight: 600;
      font-size: 16px;
    }

    .filter-options {
      text-align: left;
      position: relative;

      max-height: 400px;
      overflow-y: auto;
      margin-bottom: 1rem;
    }
  }

  @media (min-width: 700px) {
    .chart {
    margin-left: 10rem;
    margin-right: 10rem;
    }
  }
}

label {
  display: block;
  padding: .5rem 0;

  &.company {
    max-width: 120px;
    text-align: left;
    margin: 0 auto;
  }
}