.label-switch {
  --step-width: 70px;
  --width: calc(var(--step-width) * 2);
  --container-width: var(--width);
  --height: 35px;
  --container-height: var(--height);
  --font-size: 9px;
  --color: #00c6fb;

  position: relative;

  width: var(--width);
  height: var(--height);

  display: inline-block;
  border: 2px solid var(--color);
  border-radius: 5px;
  background-color: transparent;

  margin-bottom: 1rem;

  &:before {
    content: '';
    display: block;
    position: absolute;

    width: var(--step-width);
    height: var(--height);

    background-color: var(--color);
    border-radius: 2px;
    top: 0;
    left: 0;

    transition: 100ms ease-in-out;
  }

  @for $i from 0 through 5 {
    &.active-#{$i} {
      button:nth-child(#{$i + 1}) {
        color: white;
        font-weight: 600;
      }

      &:before {
        margin-left: calc(var(--step-width) * #{$i});
      }
    }
    &.steps-#{$i} {
      --width: calc(var(--step-width) * #{$i});
    }
    button:nth-child(#{$i + 1}) {
      top: 0;
      left: calc(var(--step-width) * #{$i});
    }
  }

  button {
    width: var(--step-width);
    height: var(--height);
    font-size: var(--font-size);
    text-align: center;

    position: absolute;

    padding: .5rem;
    border-radius: 2px;
    z-index: 99;

    background-color: transparent;

    outline: none;
    border: 0;

    color: #000;

    &:focus {
      outline-style: none;
      box-shadow: none;
    }
    &::-moz-focus-inner {
      border: 0;
    }
  }
}