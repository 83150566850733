.personal-data-page {
  .card {
    text-align: center;
    max-width: 600px;

    margin: 0 auto;
    padding: 1rem;

    p.title {
      font-size: 22px;
      margin-bottom: 1rem;
    }
  }
  .alterData{
    display: inline-block;
    background-color: #fff;
    border-color: #fff;
    color: #000;
    margin-bottom: 3rem;
  }
}