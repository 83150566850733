.home-page {
  .chat {
    cursor: pointer;
    padding: 16px 20px;
    position: fixed;
    bottom: 23px;
    right: 28px;

    @media screen and (max-width: 768px) {
      bottom: 0;
      right: 0;
    }
  }

  .chat-frame {
    width: 300px;
    height: 440px;

    @media screen and (max-width: 768px) {
      width: calc(100% - 0.5rem);
      height: calc(100% - 1.5rem);
    }
  }

  .home-page-grid {
    --gap: 2rem;

    display: grid;
    grid-template-rows: auto;
    grid-template-columns: 5fr 6fr;
    grid-template-areas: "invoices chart";

    grid-gap: var(--gap);

    padding-top: 50px;

    $areas: invoices, chart;
    @each $area in $areas {
      .#{$area} {
        grid-area: #{$area};
      }
    }

    @media screen and (max-width: 1200px) {
      padding-top: 1rem;
      grid-template-columns: 1fr;
      grid-template-areas:
        "invoices"
        "chart";
    }

    @media screen and (max-width: 768px) {
      padding-top: 2rem;
    }

    .last-invoice {
      .badge {
        position: absolute;
        top: 0.7rem;
        right: 1rem;
      }

      .price {
        float: right;
        font-weight: bold;
      }
    }

    .chart {
      text-align: left;
      max-width: 100%;
      overflow: hidden;
      position: relative;

      .title {
        font-weight: bold;
        font-size: 20px;
        font-style: oblique;

        margin: 1rem 0;
      }

      .chart-container {
        width: 100%;
        height: 150px;

        &.industrial {
          max-height: 300px;
        }
      }

      .navigation-bar {
        margin-top: 1rem;
        grid-template-columns: repeat(5, 1fr);
      }

      .label-switch {
        --height: 25px;
        position: absolute;
        top: 1rem;
        right: 0;

        button {
          padding: 0.2rem;
        }
      }

      &.industrial {
        grid-area: unset;
        grid-column: 1/3;
      }
    }
  }

  .personal-data-home-modal {
    text-align: left;
    p {
      margin: 0;
    }
    .content {
      margin: 2rem 0;
    }
    footer {
      text-align: center;
    }
    .field-title {
      font-weight: bold;
      size: 16px;

      margin-bottom: 0;
      margin-top: 0.5rem;
    }
  }

  & > .content {
    max-height: calc(80vh - 200px);
    padding-bottom: 50px;
  }
}
