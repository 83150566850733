.invoice-modal {
  img {
    cursor: pointer;
    display: block;
    margin: 2rem auto 1rem auto;
  }

  .badge {
    display: inline-block;
    margin: .5rem auto;
  }
}