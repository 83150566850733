.card {
  --color: grey;
  --style-color: white;
  --bg-gradient: unset;
  --border-color: transparent;
  --border-width: 0;

  position: relative;

  color: var(--color);
  background: var(--bg-gradient);

  border-width: var(--border-width, 0);
  border-style: solid;
  border-color: var(--border-color);

  padding: .6rem 1rem;
  border-radius: 5px;

  max-width: calc(100% - 3rem);

  margin-bottom: 1rem;

  text-align: left;

  -webkit-box-shadow: 4px 4px 21px -4px rgba(0,0,0,0.5);
  -moz-box-shadow: 4px 4px 21px -4px rgba(0,0,0,0.5);
  box-shadow: 4px 4px 21px -4px rgba(0,0,0,0.5);

  &.no-shadow {
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
  }

  p.title {
    font-size: 18px;
    font-weight: 600;
    font-style: oblique;

    margin-bottom: .5rem;
  }

  .footer-buttons {
    display: grid;
    grid-template-rows: 1fr;
    grid-template-columns: auto;
    grid-auto-flow: column;

    margin-top: .5rem;

    button {
      background: transparent;
      border: 0;
      margin: 0;
      color: var(--color);
    }
  }
}