.imgDinamicBanner {
  position: relative;
  cursor: pointer;
  
  height: 155px;
  width: 100%;

  -webkit-box-shadow: 4px 4px 21px -4px rgba(0, 0, 0, 0.5);
  -moz-box-shadow: 4px 4px 21px -4px rgba(0, 0, 0, 0.5);
  box-shadow: 4px 4px 21px -4px rgba(0, 0, 0, 0.5);
}