.modal-backdrop {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;

  background-color: #00000090;

  visibility: visible;
  opacity: 1;
  z-index: 998;
  overflow-y: auto;

  padding: 4rem;

  &.hidden {
    visibility: hidden;
    opacity: 0;
    transition: .3s;
  }
}
.modal {
  --padding: 1.5rem;

  position: relative;
  margin: 0 auto;
  // margin-bottom: 4rem;
  // position: fixed;
  // top: 50%;
  // left: 50%;
  // transform: translate(0, -50%);

  width: 400px;
  max-width: calc(90vw - calc(var(--padding) * 2));

  background-color: white;
  color: #444;
  padding: var(--padding);
  border-radius: .3rem;

  visibility: visible;
  opacity: 1;
  transition: .3s;
  z-index: 999;

  &.hidden {
    visibility: hidden;
    opacity: 0;
    top: 0;
    transition: .1s;
  }
  &.large {
    width: 700px;
  }
  h1 {
    margin: 0;

    color: var(--light-blue-shade);
    font-weight: 500;
    font-size: 1.5rem;
  }
  footer {
    text-align: right;
  }
  img {
    margin: .5rem;
  }
  .close-button {
    cursor: pointer;

    position: absolute;
    transform: translate(50%, -50%);
    top: 1.5rem;
    right: 1.5rem;
  }
}