.sidebar {
  --width: 300px;

  height: 100vh;
  width: 300px;
  background-color: white;
  position: fixed;
  z-index: 900;

  top: 0;

  -webkit-box-shadow: 4px 0px 21px -4px rgba(0,0,0,0.5);
  -moz-box-shadow: 4px 0px 21px -4px rgba(0,0,0,0.5);
  box-shadow: 4px 0px 21px -4px rgba(0,0,0,0.5);

  ul {
    margin-top: 50vh;
    transform: translateY(-50%);
    list-style: none;

    li {
      cursor: pointer;

      font-size: 14px;
      font-weight: 600;
      font-style: oblique;
      text-align: left;

      padding: 1rem;

      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
}
.sidebar-enter {
  opacity: .1;
  width: 0;
}
.sidebar-enter.sidebar-enter-active {
  opacity: 1;
  width: var(--width);
  transition: opacity 100ms, width 300ms;
}
.sidebar-exit {
  opacity: 1;
  width: var(--width);
}
.sidebar-exit.sidebar-exit-active {
  opacity: .1;
  width: 0;
  transition: opacity 100ms, width 300ms;
}
.sidebar-exit-done {
  opacity: 0;
  width: 0;
}