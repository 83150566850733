.consumption-page {
  .consumption-page-grid {
    --gap: 2rem;

    display: grid;
    grid-template-rows: auto;
    grid-template-columns: 8fr 4fr;
    grid-template-areas: "chart invoices";

    grid-gap: var(--gap);

    padding-top: 50px;

    $areas: invoices, chart;
    @each $area in $areas {
      .#{$area} { grid-area: #{$area}; }
    }

    @media screen and (max-width: 1200px) {
      padding-top: 1rem;
      grid-template-columns: 1fr;
      grid-template-areas:
        "chart"
        "invoices";
    }

    @media screen and (max-width: 768px) {
      padding-top: 2rem;
    }

    .average {
      display: block;
      font-size: 20px;
      color: #fd961d;
    }

    &.industrial {
      grid-template-columns: 1fr;
    }

    .last-invoice {
      .badge {
        display: inline-block;
        margin-bottom: 2rem;
      }

      .price {
        float: right;
        font-weight: bold;
      }
    }

    .chart {
      text-align: left;
      max-width: 100%;
      overflow: hidden;
      position: relative;

      .title {
        font-weight: bold;
        font-size: 16px;
        font-style: oblique;

        margin: 1rem 0;

        .value {
          display: block;
          font-size: 22px;
          color: #fd961d;
        }
      }

      .chart-container {
        width: 100%;
        height: 150px;
      }

      .navigation-bar {
        margin-top: 1rem;
        grid-template-columns: repeat(5, 1fr);
      }

      .label-switch {
        --height: 25px;
        position: absolute;
        top: 1rem;
        right: 0;

        button {
          padding: .2rem;
        }
      }
    }
  }
  .invoice-in-focus {
    padding: 1rem;

    .interval {
      color: #888;
      float: right;
    }

    .consumption {
      display: inline-block;
      width: 50%;
      margin-top: 1.5rem;
      font-size: 18px;

      .value {
        margin-left: .5rem;
        font-weight: bold;
      }
    }
  }

  & > .content {
    max-height: calc(80vh - 200px);
    padding-bottom: 50px;
  }

  .filter-modal {
    position: relative;

    .title {
      margin-bottom: 0;
      margin-top: 1rem;
      color: var(--light-blue-shade);
      font-weight: 600;
      font-size: 16px;
    }

    .filter-options {
      text-align: left;
      position: relative;

      max-height: 400px;
      overflow-y: auto;
      margin-bottom: 1rem;
    }

  }

  .grid {
    display: grid;
  width: 100%;
  grid-template-columns: 1fr 1fr;
  }

  @media (min-width: 700px) {
    .grid {
    grid-template-columns: 8fr 2.5fr;
    }
  }

  .ticket-in-focus {
    .address-item {
      .title {
        color: var(--light-blue-shade)
      }
      .item {
        display: flex;
        flex-direction: row;
        justify-content: space-between;

        font-size: 16px;

        small {
          display: block;
          font-size: 12px;
        }
      }
    }
  }
}