.home-services-page {
  .content {
    margin-top: 180px;
    max-height: calc(80vh - 200px);
    padding-bottom: 50px;
  }

  .service-card {
    border: none;

    -webkit-box-shadow: 4px 8px 18px -4px rgba(0,0,0,0.5);
    -moz-box-shadow: 4px 8px 18px -4px rgba(0,0,0,0.5);
    box-shadow: 4px 8px 18px -4px rgba(0,0,0,0.5);
  }

  .label-switch {
    --step-width: 120px;
  }

  .protocol-list {
    display: block;
  }

  .protocol-item {
    padding: .5rem 1rem;
    border-radius: 5px;

    .title {
      font-style: oblique;
      max-width: 50%;
    }

    .badge {
      position: absolute;
      top: .5rem;
      right: 1rem;
      max-width: 50%;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  .protocol-detail-modal {
    .title {
      font-size: 16px;
      font-weight: 600;
      margin: 0;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
      font-style: oblique;
    }

    .value {
      font-weight: bold;
      font-size: 18px;

      margin-bottom: .3rem;

      &.blue {
        color: var(--light-blue);
      }
    }

    .badge-cancel {
        padding: 8px 20px;
        margin-bottom: 15px;
        display: inline-block;
    }
  }
}