body {
  margin: 0;
  font-family: -apple-system, ProximaNova, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  font-size: 14px;
}

:root {
  --dark-blue: rgb(7, 35, 64);
  --dark-blue-tint: rgb(5, 52, 98);
  --light-blue: rgb(0, 196, 255);
  --light-blue-shade: rgb(32, 101, 168);
  --red: #e15f54;
  --blue: #1c5a97;
  --yellow: #fd961d;
  --green: #00a326;
}

@font-face {
  font-family: "ProximaNova";
  font-weight: 600;
  src: URL("assets/Proxima-Nova-Semibold.woff") format("woff"),
    /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
      URL("assets/Proxima-Nova-Semibold.ttf") format("truetype"); /* Chrome 4+, Firefox 3.5, Opera 10+, Safari 3—5 */
}
@font-face {
  font-family: "ProximaNova";
  font-weight: 400;
  src: URL("assets/Proxima-Nova-Regular.woff") format("woff"),
    /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
      URL("assets/Proxima-Nova-Regular.ttf") format("truetype"); /* Chrome 4+, Firefox 3.5, Opera 10+, Safari 3—5 */
}
@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

h1 {
  font-style: oblique;
  margin: 0;
  font-size: 28px;
}

p {
  line-height: 1.5;
  margin-top: 0;
  font-size: 14px;
}

button,
a {
  --padding: 0.5rem;

  display: inline-block;
  background-color: var(--light-blue);

  border: 1px solid var(--light-blue);
  border-radius: 3rem;

  padding: var(--padding);
  margin-bottom: 1rem;

  text-transform: uppercase;
  text-decoration: none;
  text-align: center;
  color: white;
  font-weight: 500;
  font-size: calc(8px + 0.3vw);

  cursor: pointer;

  &:disabled {
    cursor: default;
    opacity: 0.8;
  }

  &.link {
    background-color: transparent;
    color: var(--light-blue-shade);
    font-weight: 500;

    border: none;
    margin: 0;
    padding: 0.5rem;

    &.white {
      color: white;
    }
    &.secondary {
      color: grey;
    }
  }
  &.inline-link {
    background-color: transparent;
    color: var(--light-blue);
    font-weight: normal;

    border: none;
    margin: 0;
    padding: 0;
    text-decoration: underline;
    text-transform: none;

    &.white {
      color: white;
    }
  }
  &.block {
    --block-width: 350px;
    --width-compensation: calc(var(--padding, 0) * 2);
    width: var(--block-width);
    max-width: calc(
      var(--container-width, 100vw) - var(--width-compensation, 0)
    );
  }
  &.outline {
    background-color: transparent;
  }
  &.danger {
    color: var(--red);
  }
  &.bg-danger {
    background: var(--red);
    border-color: var(--red);
  }
  &.clear {
    background: unset;
    padding: unset;
    border: unset;
    color: unset;
    font-variant: unset;
    font-weight: unset;
    font-size: unset;
    text-transform: unset;
    margin: unset;
  }

  &.rectangle {
    border-radius: 6px;
    font-size: calc(6px + 0.3vw);
  }
}
a.block {
  --block-width: calc(350px - var(--width-compensation));
}
button.block {
  --width-compensation: 0rem;
}

input,
select {
  --padding: 0.5rem;

  margin-bottom: 2rem;
  background-color: transparent;

  padding: var(--padding);

  border-width: 0 0 1px 0;
  border-color: var(--light-blue);

  text-align: center;
  color: var(--light-blue);

  -moz-appearance: none; /* Firefox */
  -webkit-appearance: none; /* Safari and Chrome */
  appearance: none;

  &.block {
    width: calc(350px - calc(var(--padding, 0) * 2));
  }
  &.error {
    border-color: var(--red);
    color: var(--red);
  }
}
.wrap-material-ui-select {
  display: flex;
  justify-content: center;
  .material-ui-select {
    margin: 0 5px;
  }
}

.material-ui-select {
  text-align: center;
  align-items: center;
  padding: var(--padding);
  border-bottom: 1px solid var(--light-blue);

  svg {
    color: var(--light-blue)
  }
  input {
    color: var(--light-blue);
  }
}

.material-ui-select .MuiInputBase-input {
  color: var(--light-blue) !important;
}

input.block {
  --width-compensation: calc(var(--padding) * 2);
  max-width: calc(var(--container-width, 100vw) - var(--width-compensation));
}

input[type="radio"] {
  --size: 16px;

  cursor: pointer;
  margin: 0;

  width: var(--size);
  height: var(--size);

  border: 1px solid grey;
  border-radius: 100%;

  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;

  transform: translateY(4px);

  margin-right: 0.3rem;

  &:checked, &.checked {
    background-color: transparent;
    border-color: transparent;

    width: calc(var(--size) + 2px);
    height: calc(var(--size) + 2px);

    box-shadow: inset 0 0 0 5px var(--light-blue);
  }
}

input[type="checkbox"] {
  --size: 16px;
  margin: 0;

  width: var(--size);
  height: var(--size);

  border: 1px solid grey;
  border-radius: 5px;

  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;

  transform: translateY(4px);
  position: relative;

  margin-right: 0.3rem;

  &:checked, &.checked {
    background-color: var(--light-blue);
    border-color: var(--light-blue);

    &:after {
      content: "✓";
      color: white;
      position: absolute;

      transform: translate(-50%, -50%);
    }
  }

  &:disabled {
    cursor: default;
    opacity: 0.6;
  }
}

button,
input,
select {
  outline: none;
}

select {
  margin-left: -0.5rem;
  padding-right: 2rem;

  &.block {
    max-width: var(--container-width, 100vw);
  }
}
.white-background {
  select {
    color: #444;
  }
}
.no-margin {
  margin: 0 !important;
}

.text-center {
  text-align: center !important;
}

.badge {
  font-size: 12px;
  border-radius: 15px;

  padding: 0.2rem 0.5rem;

  &.primary {
    background: var(--blue);
    color: #fff;
  }

  &.warning {
    background: var(--yellow);
    color: #fff;
  }

  &.success {
    background: var(--green);
    color: #fff;
  }

  &.danger {
    background: var(--red);
    color: #fff;
  }
}

.price-badge {
  display: inline-block;
  border: 1px solid var(--light-blue);
  border-radius: 3rem;
  text-transform: uppercase;
  text-align: center;
  padding: 0.2rem;
  color: #444;
  font-weight: 400;
  width: 80px;
}

hr {
  border-color: #eee;
}