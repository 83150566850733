.page-container {
  --container-width: 90vw;
  --container-max-width: var(--container-width);

  width: var(--container-width);
  max-width: var(--container-max-width);
  max-height: 100vh;
  margin: 0 auto;

  overflow-y: auto;
  overflow-x: hidden;

  position: absolute;
  transform: translate(-50%, -50%);
  left: 50%;
  top: 45%;

  z-index: 900;
}