.consumption-modal {
  .content {
    margin: 0;
    padding: 0;
    max-height: unset;
    margin-right: 1rem;
    text-align: left;

    .badge {
      display: inline-block;
      margin: .5rem 0;
    }

    .value {
      font-weight: bold;
      font-size: 18px;

      margin-bottom: .3rem;

      &.blue {
        color: var(--light-blue);
      }
    }
  }
}