.delivery-card {
  position: relative;
  display: inline-block;
  width: calc(120px + 5vw);
  height: calc(80px + 5vw);

  background-color: #fff;
  border-radius: 0.3rem;
  margin: 0.35rem;
  text-align: center;
  color: #444;

  border: 1px solid var(--light-blue);

  &.checked {
    border: 3px solid var(--green, green);
    transition: 0.1s;

    .icon-check {
      position: absolute;
      top: -24px;
      left: 90%;
      transform: translateX(-50%);
      border-radius: 100%;
      padding: 4px;
      background-color: white;
      border: 3px solid var(--green, green);
    }
  }

  .title {
    font-size: calc(10px + 0.3vw);
    font-weight: 600;
    font-style: oblique;
    padding: 0.5rem 0.7rem;
    margin: 0;

    display: inline-block;
    text-align: left;
    position: unset;
    transform: unset;
  }

  .image {
    position: absolute;
    height: 50%;
    bottom: 10px;
    right: 0;
  }

  .imgservice {
    position: absolute;
    height: 90%;
    bottom: 10px;
    right: 0;
  }

  &:hover {
    cursor: pointer;
  }
}

@media screen and (max-width: 576px) {
  .delivery-card {
    width: calc(90vw - 2rem);
    max-width: 220px;
    height: 50vh;
    max-height: 300px;

    .title {
      top: calc(20% + 1.8vw);
      width: calc(200px + 3vw);
      font-size: calc(14px + 0.3vw);
    }
    .image {
      margin-top: 80%;
      float: right;
      height: 40%;
    }
  }
}
