.address-list-page {
  .card {
    cursor: pointer;

    display: grid;
    grid-template-rows: 1fr;
    grid-template-columns: 40px 1fr 40px;
    grid-auto-flow: column;
    grid-gap: 2rem;

    max-width: 500px;

    margin-left: auto;
    margin-right: auto;
    padding: 2rem;

    > *:first-child,
    > *:last-child {
      position: relative;
    }

    p,
    p.title {
      margin: 0;
      line-height: 1.2;
    }

    .icon-button {
      cursor: pointer;

      height: 30px;
      width: 30px;

      padding: 5px;

      border-radius: 100%;
      border: 1px solid grey;
      display: block;

      &.success {
        background-color: #43be39;
        border-color: #43be39;
        color: white;
      }
    }
  }

  .fab-add-address {
    position: fixed;

    bottom: 3rem;
    right: 3rem;

    width: 50px;
    height: 50px;
  }

  .content {
    max-height: calc(80vh - 200px);
    padding-bottom: 50px;
  }
}
