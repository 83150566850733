.contestation-page {
  .content {
    max-width: 700px;
    max-height: calc(80vh - 200px);

    .value {
      font-weight: bold;
      font-size: 18px;

      margin-bottom: .3rem;

      &.blue {
        color: var(--light-blue);
      }
    }
  }
}