.navigation-bar {
  --color: black;

  width: 100%;
  display: block;

  display: flex;
  flex-direction: row;
  justify-content: space-between;
  &.months-nav{
      button{
        padding: 0.5rem 1rem;
      }
  }
  button {
    border: none;
    padding: 1rem;
    font-style: oblique;

    background-color: transparent;
    color: var(--color);

    white-space: nowrap;

    &.active-undermark {
      position: relative;
      color: #ffffff;
      background-color: #114679;

    //   &:after {
    //     content: '';
    //     position: absolute;
    //     transform: translateX(-50%);

    //     left: 50%;
    //     bottom: 0;

    //     width: 40px;
    //     height: 5px;
    //     border-top-left-radius: 5px;
    //     border-top-right-radius: 5px;
    //     background-color: var(--color);
    //   }
    }
    &.active-fill {
      background: rgb(22,74,127);
      background: linear-gradient(33deg, rgba(22,74,127,1) 0%, rgba(10,63,110,1) 100%);
      color: white;

      border-radius: 5px;
    }
    &.disabled {
      opacity: .4;
    }
  }

  .icon {
    margin-right: .5rem;
    margin-top: -10px;
    transform: translateY(5px);
  }
}