.address-select-page {
  overflow: hidden;
  .card {
    cursor: pointer;
    display: grid;
    grid-template-rows: 1fr;
    grid-template-columns: 40px 1fr 40px;
    grid-auto-flow: column;
    grid-gap: 2rem;

    max-width: 500px;
    margin-left: auto;
    margin-right: auto;
    padding: 2rem;

    > *:first-child, > *:last-child {
      position: relative;
      > * {
        position: absolute;
        transform: translateY(-50%);
        top: 50%;
      }
    }
    p, p.title {
      line-height: 1.2;
    }

    .icon-button {
      cursor: pointer;

      height: 30px;
      width: 30px;

      padding: 5px;

      border-radius: 100%;
      border: 1px solid grey;

      &.success {
        background-color: #43be39;
        border-color: #43be39;
        color: white;
      }
    }
  }
  .fab-add-address {
    position: fixed;

    bottom: 3rem;
    right: 3rem;

    width: 50px;
    height: 50px;
  }

  .content {
    max-height: calc(80vh - 200px);
    padding-bottom: 50px;
  }

  h1 {
    max-width: 550px;
    margin-left: auto;
    margin-right: auto;
    text-align: left;
    margin-top: 40px;
  }
  p {
    max-width: 550px;
    margin-left: auto;
    margin-right: auto;
    text-align: left;
  }

  .page-container {
    max-height: calc(80vh);

    margin: 0;
    margin-top: 100px!important;
  }

  .header-fade {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    height: 150px;
    background: rgb(4,19,36);
    background: linear-gradient(0deg, rgba(4,19,36,0) 0%, rgba(4,19,36,1) 50%);
    z-index: 900;
  }
}