.cancellation-page {
  .text-blue {
    color: var(--light-blue);
  }

  .border-bottom {
    border-bottom: 1px solid var(--light-blue);
  }
  
  .wrap-itens {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 30;

    .item {
      display: flex;
      flex-direction: column;
      align-items: center;
    }
  }

  .content {
    max-width: 700px;
    max-height: calc(80vh - 200px);
  }

  h1 {
    color: #DC3E31;
    margin-top: 10px;
    margin-bottom: 10px;
  }

  .service-item {
    margin: .5rem auto;

    &.address-item {
      display: flex;
      flex: 1;
      flex-direction: row;
      justify-content: space-between;;
      align-items: center;
    }

    h2 {
      margin: 0;
    }
    .value {
      color: var(--blue);
    }
    p {
      padding: .3rem 1rem;
      margin: 0;
    }
  }
}