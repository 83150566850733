.client-recommendation-page {
  .card {
    display: grid;
    grid-template-rows: 1fr;
    grid-template-columns: 40px 1fr 40px;
    grid-auto-flow: column;
    grid-gap: 2rem;

    max-width: 500px;

    margin-left: auto;
    margin-right: auto;
    padding: 2rem;

    > *:first-child,
    > *:last-child {
      position: relative;
      > * {
        position: absolute;
        transform: translateY(-50%);
        top: 50%;
      }
    }

    p,
    p.title {
      margin: 0;
      line-height: 1.2;
    }
  }

  .business-name,
  .business-tel {
    color: #444;
    border-color: #444;
    margin-bottom: 1rem;
  }

  .business-name label,
  .business-tel label {
    padding: 0 0;
  }

  .rectangle {
    color: #444;
    background-color: white;
    border-radius: 20px;
    border-color: #444;
  }

  .content {
    max-height: calc(80vh - 200px);
    padding-bottom: 50px;
  }
}
