.home-template {
  --gap: 2rem;
  --max-width: 1024px;

  button.outline, a.outline {
    color: grey;
    font-weight: bold;
  }

  &:after {
    content: '';

    position: fixed;
    z-index: 900;

    top: 0;
    left: 0;

    width: 100%;
    height: 150px;

    background: rgb(255,255,255);
    background: linear-gradient(0deg, rgba(255,255,255,0) 0%, rgba(255,255,255,1) 30%);
  }
  .header-bg {
    position: fixed;
    z-index: 980;

    top: 0;
    left: 0;

    background-position: center;
    background-size: cover;
    width: 100vw;
    height: 9.12vw;
  }
  .header {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    width: 100vw;

    z-index: 990;

    display: grid;
    grid-template-rows: 1fr;
    grid-template-columns: 5fr 6fr;
    grid-template-areas:
      "wellcome navbar";

    grid-gap: var(--gap);
    padding: var(--gap);
    padding-top: calc(1.5rem + .7vw);

    max-width: var(--max-width);
    margin: 0 auto;

    $areas: wellcome, navbar;
    @each $area in $areas {
      .#{$area} { grid-area: #{$area}; }
    }

    .wellcome {
      --color: #fff;

      color: var(--color);
      text-align: left;

      .menu-icon {
        float: left;
        margin: .3rem;
        margin-left: -50px;
      }

      .title {
        font-size: 24px;
        font-weight: 600;
        font-style: oblique;
        line-height: 1;

        margin: 0;

        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;

        @media screen and (min-width: 768px) {
          max-width: 350px;
          width: 25vw;
        }

        small {
          font-size: 10px;
          font-style: normal;
          color: var(--light-blue);
          display: block;
          margin-bottom: .5rem;
        }
      }

      select {
        margin: 0;
        border: none;
        color: var(--color);

        padding-top: 0;
        padding-bottom: 0;
        padding-left: 0;

        text-align: left;

        font-size: 10px;
        line-height: 1;

        max-width: calc(120px + 8vw);
        overflow: hidden;
        text-overflow: ellipsis;

        option {
          color: black;
        }
      }
    }

    .navbar {
      display: grid;
      grid-template-columns: 1fr 50px;
      padding-top: calc(1.5rem + .7vw);
      grid-gap: 2rem;

      .navigation-bar {
        z-index: 800;
      }
      img {
        width: 50px;
      }
    }


    @media screen and (max-width: 1200px) {
      max-width: calc(100vw - 4rem);

      .wellcome {
        .menu-icon {
          float: left;
          margin: .3rem;
          margin-right: 1rem;
        }
      }
    }

    @media screen and (max-width: 768px) {
      position: unset;
      grid-template-columns: 1fr;
      grid-template-areas:
        "wellcome"
        "navbar";

      padding-left: 0;
      padding-right: 0;

      .wellcome {
        position: absolute;
        width: calc(100% - calc(2 * var(--gap)));
        z-index: 990;
      }
      .navbar {
        margin-top: 40px;
        grid-template-columns: 1fr 30px;

        img {
          margin-top: .5rem;
          width: 30px;
        }
      }
      .header-bg {
        background-size: cover;
        width: 100vw;
        height: 200px;
        background-color: blue;
      }
    }
  }
  .content {
    position: relative;
    max-width: var(--max-width);
    margin: 0 auto;
    margin-top: 150px;

    @media screen and (max-width: 1200px) {
      max-width: calc(100vw - 4rem);

      .notification-container {
        top: 0;
        right: 0;
      }
    }

    @media screen and (max-width: 768px) {
      margin-top: 0;
      max-width: calc(100vw - 4rem);
    }
  }

  @media screen and (max-width: 768px) {
    .header-bg {
      background-size: cover;
      background-image: none !important;
      width: 100vw;
      height: 100px;
      background-color: var(--dark-blue);
    }

    &:after {
      content: none;
    }
  }

  .top-nav {
    @media screen and (max-width: 768px) {
      button:not([class^="active"]) {
        .label {
          display: none;
        }
        .icon {
          margin-right: 0;
        }
      }
    }
  }


}