.page {
  --container-max-width: 100vw;

  position: relative;

  width: 100%;
  min-height: 100vh;

  text-align: center;
  background: rgb(2,0,36);
  background: linear-gradient(45deg, var(--dark-blue) 0%, var(--dark-blue) 40%, var(--dark-blue-tint) 100%);

  color: white;

  overflow-x: hidden;

  &.white-background {
    background: #fff;

    color: #444;
  }

  .footer-image {
    width: 100vw;
    position: fixed;
    left: 0;
    right: 0;
    bottom: 0;
  }

  .footer-logo-image {
    position: fixed;
    height: 5vw;
    max-height: 50px;
    right: 16vw;
    bottom: 4.5vw;
    transform: translate(50%, 50%);
  }
}
