@keyframes showToast {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
.toast-backdrop {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;

  background-color: #00000040;

  visibility: visible;
  opacity: 1;
  z-index: 998;

  animation: showToast .2s;
}
.toast {
  --padding: 1rem;

  position: fixed;
  top: 50vh;
  left: 50vw;

  width: 200px;
  max-width: calc(90vw - calc(var(--padding) * 2));
  transform: translate(-50%, -50%);

  background-color: #101010E2;
  color: #fff;
  padding: var(--padding);
  border-radius: .3rem;

  visibility: visible;
  opacity: 1;
  transition: .3s;
  z-index: 999;

  animation: showToast .2s;

  word-wrap: break-word;

  .title {
    font-size: 24px;
    font-weight: 600;
    margin-bottom: .5rem;
  }
}