.service-item {
  --margin: .5rem;
  --padding-x: 1.7rem;
  --padding-y: .4rem;

  position: relative;
  display: inline-block;

  width: calc(400px - 5rem);
  --width-compensation: calc(calc(var(--padding-x) * 2) + calc(var(--margin) * 2));
  max-width: calc(var(--container-width, 100vw) - var(--width-compensation));

  border: 1px solid var(--light-blue);
  padding: var(--padding-y) var(--padding-x);
  margin: var(--margin);

  font-size: 12px;

  text-align: left;

  .title {
    font-size: 16px;
    font-weight: 600;
    margin: 0;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }
  .close-button {
    position: absolute;
    transform: translateY(-50%);
    top: 50%;
    right: .5rem;

    cursor: pointer;
  }
}