.notification-container {
  position: absolute;

  top: 2rem;
  right: 2rem;

  width: 350px;
  max-width: 80vw;

  z-index: 800;

  .notification {
    position: relative;

    .icon-close {
      cursor: pointer;

      transform: translate(-50%, -50%);
      position: absolute;
      top: 50%;
      left: 1.5rem;
    }

    .title {
      line-height: 1;
      margin: 0;
    }

    &.clickable {
      cursor: pointer;
    }
  }
  .notification-enter {
    opacity: .5;
  }
  .notification-enter.notification-enter-active {
    opacity: 1;
    transition: opacity 100ms;
  }
  .notification-exit {
    opacity: 1;
  }
  .notification-exit.notification-exit-active {
    opacity: .5;
    transition: opacity 100ms;
  }
  .notification-exit-done {
    opacity: 0;
  }
}