.service-detail-title {
  margin-top: 0;
  text-align: left;
  font-style: oblique;
}

.changeData {
  margin-bottom: 0.8rem;
  border-color: #444;
  width: 60%;
  color: #444;
}

.border-info {
  border: 2px solid var(--light-blue);
  border-radius: 15px;
}

.pgto-prazo {
  border: 2px solid var(--light-blue);
  width: 100px;
  margin: auto;
}

.pgto-prazo p {
  margin-bottom: 0;
}

.service-detail-footer {
  --container-width: 100%;
  background: var(--dark-blue);
  text-align: center;
  margin: calc(var(--padding) * -1);
  margin-top: var(--padding);
  border-bottom-right-radius: 0.3rem;
  border-bottom-left-radius: 0.3rem;
  padding: var(--padding);
  color: white;

  button {
    display: block;
    margin: 0 auto;
    margin-bottom: 1rem;

    &:last-child {
      margin-bottom: 0;
    }
  }
}

.text-blue {
  color: var(--light-blue);
}

.border-bottom {
  border-bottom: 1px solid var(--light-blue);
}

.wrap-itens {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 30;

  .item {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 20px;
    padding: 10px;
  }
}
